<template>
  <div class="headerTable">
    <table v-if="data.length" id="searchBar" border="1">
      <thead id="thead">
        <tr>
          <th rowspan="2" class="td1">学生</th>
          <th v-if="showColumn('schoolRank')" rowspan="2" class="td2">
            校排名
          </th>
          <th :colspan="objectiveQuestionsLength">客观题</th>
          <th :colspan="subjectiveQuestionsLength">主观题</th>
        </tr>
        <tr>
          <th
            v-for="(item, index) in head.objectiveQuestions"
            :key="'objectiveQuestionsQuestionOrder' + index"
          >
            {{ item.questionOrder }}
          </th>
          <th
            v-for="(item, index) in head.subjectiveQuestions"
            :key="'subjectiveQuestionsQuestionOrder' + index"
          >
            {{ item.questionOrder }}
          </th>
        </tr>
        <tr>
          <th :colspan="showColumn('schoolRank') ? 2 : 1" class="td1">
            正确答案/满分分值
          </th>
          <th
            v-for="(item, index) in head.objectiveQuestions"
            :key="'objectiveQuestionsError' + index"
          >
            <span v-if="item.questionOrder === '客观题'">{{ item.score }}</span>
            <span v-else>{{ item.rightAnswer }}</span>
          </th>
          <th
            v-for="(item, index) in head.subjectiveQuestions"
            :key="'subjectiveQuestionsError' + index"
          >
            {{ item.score }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in body" :key="'body' + index">
          <td class="td1">
            <el-button type="text" @click="toStudentDetail(item)">{{
              item.name
            }}</el-button>
          </td>
          <td v-if="showColumn('schoolRank')" class="td2">
            {{ item.schoolRank }}
          </td>
          <td
            v-for="(it, ind) in head.objectiveQuestions"
            :key="'bodyObjectiveQuestions' + ind"
          >
            <span>{{ getQuestionOrder(item, it) }}</span>
          </td>
          <td
            v-for="(it, ind) in head.subjectiveQuestions"
            :key="'bodySubjectiveQuestions' + ind"
          >
            <span>{{ getsubjectiveQuestionOrder(item, it) }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <no-data v-else />
  </div>
</template>

<script>
export default {
  name: "HeaderTable",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    topData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      head: {},
      body: {},
      objectiveQuestionsLength: 0,
      subjectiveQuestionsLength: 0,
      examType: this.$route.query.examType,
    };
  },
  watch: {
    data(val) {
      if (val.length) {
        this.head = {
          objectiveQuestions: val[0].questions.filter(
            (item) => item.isObjective == 1
          ),
          subjectiveQuestions: val[0].questions.filter(
            (item) => item.isObjective == 0
          ),
        };
        this.body = [...val];
        this.subjectiveQuestionsLength = this.head.subjectiveQuestions.length;
        this.objectiveQuestionsLength = this.head.objectiveQuestions.length;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    showColumn(field) {
      if (this.examType == 1) {
        if (this.topData.isSchoolDisplayReportClassRank == 1) {
          return true;
        }
        return false;
      } else {
        if (this.data.length) {
          const result = this.data.every((item) => item[field] === null);
          return !result;
        }
      }
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; //滑动的距离
      this.$nextTick(() => {
        if (!document.querySelector("#searchBar")) {
          return false;
        }
        let heightTop = document.querySelector("#searchBar")
          ? document.querySelector("#searchBar").offsetTop
          : 0;
        if (scrollTop >= heightTop) {
          //表头到达页面顶部固定表头
          let top = scrollTop - heightTop + 70;
          document.getElementById("thead").style.position = "relative";
          document.getElementById("thead").style.zIndex = "2";
          document.getElementById("thead").style.top = `${top}px`;
        } else if (scrollTop == 0) {
          //表格横向
          // console.log('横拉')
          document.getElementById("thead").style.position = "relative";
          document.getElementById("thead").style.zIndex = "2";
        } else {
          document.getElementById("thead").style.position = "";
          document.getElementById("thead").style.top = "";
          document.getElementById("thead").style.zIndex = "";
        }
      });
    },
    toStudentDetail(item) {
      const { href } = this.$router.resolve({
        path: "/academic/reportStat/studentReport",
        query: {
          studentId: item.studentId,
          ...this.$route.query,
        },
      });
      window.open(href, "_blank");
    },
    getQuestionOrder(item, it) {
      const data = item.questions.find(
        (i) => i.questionOrder === it.questionOrder
      );

      if (it.questionOrder === "客观题") {
        return data.lostScore;
      }
      return data.wrongAnswer;
    },
    getsubjectiveQuestionOrder(item, it) {
      const data = item.questions.find(
        (i) => i.questionOrder === it.questionOrder
      );
      return data.lostScore;
    },
  },
};
</script>
<style lang="scss" scoped>
.headerTable {
  width: 100%;
  overflow-x: auto;

  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 20px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
    background: #999;
  }
  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(87, 175, 187, 0.1);
    border-radius: 10px;
    background: #ededed;
  }
  table {
    width: 100%;
    border-color: #ebeef5;
    tr {
      th {
        min-width: 80px;
        height: 37px;
        background-color: #f2f5f8;
        text-align: center;
        font-size: 14px;
        color: #101011;
      }
      & :last-child {
        // border-right: none;
        outline: none;
      }
      td {
        min-width: 80px;
        height: 42px;
        background-color: #fff;
        text-align: center;
        font-size: 14px;
        color: #909399;
      }
    }
  }
}
.td1 {
  position: sticky;
  text-align: center;
  z-index: 1;
  outline: 1px solid #ebeef5;
  left: 0;

  box-sizing: border-box;
}
.td2 {
  position: sticky;
  z-index: 1;
  text-align: center;
  outline: 1px solid #ebeef5;

  box-sizing: border-box;

  left: 80px;
}
</style>
