import request from "@/core/services/axios";
// 任课老实报告
export function getrenkejiaoshireporttopinfo(params) {
  // 获取任课老师报告顶部数据
  return request({
    url: `/report/statrenkejiaoshireport/getrenkejiaoshireporttopinfo`,
    method: "get",
    params: params,
  });
}
export function downloadrenkejiaoshipdfreport(params) {
  // 下载任课教师报告PDF文档
  return request({
    url: `/report/statrenkejiaoshireport/downloadrenkejiaoshipdfreport`,
    method: "get",
    params: params,
  });
}
export function getrkjsreportclasscompetitiveOnline(params) {
  // 任课教师报告-班级竞争力-上线情况
  return request({
    url: `/report/statrenkejiaoshireport/getrkjsreportclasscompetitive/online`,
    method: "get",
    params: params,
  });
}
export function getrkjsreportclasscompetitiveRatio(params) {
  // 任课教师报告-班级竞争力-上线情况
  return request({
    url: `/report/statrenkejiaoshireport/getrkjsreportclasscompetitive/ratio`,
    method: "get",
    params: params,
  });
}
export function getrkjsreportclasscompetitiveTopsection(params) {
  // 任课教师报告-班级竞争力-前N名
  return request({
    url: `/report/statrenkejiaoshireport/getrkjsreportclasscompetitive/topsection`,
    method: "get",
    params: params,
  });
}
export function getrkjsreportclasscompetitiveOther(params) {
  // 任课教师报告-班级竞争力-其他指标
  return request({
    url: `/report/statrenkejiaoshireport/getrkjsreportclasscompetitive/other`,
    method: "get",
    params: params,
  });
}
export function getrkjsreportscoredistributionScore(params) {
  // 任课教师报告-成绩分布-分数分布
  return request({
    url: `/report/statrenkejiaoshireport/getrkjsreportscoredistribution/score`,
    method: "post",
    data: params,
  });
}
export function getrkjsreportscoredistributionRank(params) {
  // 任课教师报告-成绩分布-排名分布
  return request({
    url: `/report/statrenkejiaoshireport/getrkjsreportscoredistribution/rank`,
    method: "post",
    data: params,
  });
}
export function getrkjsreportimportstudentTrack(params) {
  // 任课教师报告-重点关注学生-跟踪生
  return request({
    url: `/report/statrenkejiaoshireport/getrkjsreportimportstudent/track`,
    method: "get",
    params: params,
  });
}
export function getrkjsreportimportstudentCritical(params) {
  // 任课教师报告-重点关注学生-临界生
  return request({
    url: `/report/statrenkejiaoshireport/getrkjsreportimportstudent/critical`,
    method: "get",
    params: params,
  });
}
export function getrkjsreportimportstudentWave(params) {
  // 任课教师报告-重点关注学生-波动生
  return request({
    url: `/report/statrenkejiaoshireport/getrkjsreportimportstudent/wave`,
    method: "get",
    params: params,
  });
}
export function getrkjsreportimportstudentToporbackward(params) {
  // 任课教师报告-重点关注学生-后进生-拔尖生
  return request({
    url: `/report/statrenkejiaoshireport/getrkjsreportimportstudent/toporbackward`,
    method: "get",
    params: params,
  });
}
export function getrkjsreportdevelopanalysisOveraveragerate(params) {
  // 任课教师报告-发展性分析-超均率
  return request({
    url: `/report/statrenkejiaoshireport/getrkjsreportdevelopanalysis/overaveragerate`,
    method: "get",
    params: params,
  });
}
export function getrkjsreportdevelopanalysisOnline(params) {
  // 任课教师报告-班级竞争力-上线情况
  return request({
    url: `/report/statrenkejiaoshireport/getrkjsreportdevelopanalysis/online`,
    method: "get",
    params: params,
  });
}
export function getrkjsreportdevelopanalysisRatio(params) {
  // 任课教师报告-发展性分析-比率
  return request({
    url: `/report/statrenkejiaoshireport/getrkjsreportdevelopanalysis/ratio`,
    method: "get",
    params: params,
  });
}
export function getrkjsreportdevelopanalysisTopsection(params) {
  // 任课教师报告-班级竞争力-前N名
  return request({
    url: `/report/statrenkejiaoshireport/getrkjsreportdevelopanalysis/topsection`,
    method: "get",
    params: params,
  });
}
export function getrkjsreportdevelopanalysisOther(params) {
  // 任课教师报告-发展性分析-其他指标
  return request({
    url: `/report/statrenkejiaoshireport/getrkjsreportdevelopanalysis/other`,
    method: "get",
    params: params,
  });
}
export function getrkjsreportpaperanalyStudent(params) {
  // 任课教师报告-试卷分析-学生应答反馈
  return request({
    url: `/report/statrenkejiaoshireport/getrkjsreportpaperanaly/student`,
    method: "get",
    params: params,
  });
}
export function getrkjsreportpaperanalyKnowledge(params) {
  // 任课教师报告-试卷分析-应答情况分析-知识点
  return request({
    url: `/report/statrenkejiaoshireport/getrkjsreportpaperanaly/knowledge`,
    method: "get",
    params: params,
  });
}
export function getrkjsreportpaperanalyQuestion(params) {
  // 任课教师报告-试卷分析-应答情况分析-题目
  return request({
    url: `/report/statrenkejiaoshireport/getrkjsreportpaperanaly/question`,
    method: "get",
    params: params,
  });
}
export function getrkjsreportdevelopanalysisV2(params) {
  // 任课教师报告-发展性分析V2
  return request({
    url: `/report/statrenkejiaoshireport/getrkjsreportdevelopanalysis/v2`,
    method: "get",
    params: params,
  });
}
export function exportTeacherReport(params) {
  // 任课教师报告-导出
  return request({
    url: `/report/statrenkejiaoshireport/export`,
    method: "post",
    data: params,
  });
}

export function exportTeacherReportNew(params) {
  // 任课教师报告-导出 - 新高考
  return request({
    url: `/report/statnewgaokaorenkejiaoshireport/export`,
    method: "post",
    data: params,
  });
}
