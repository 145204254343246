<template>
  <div ref="student" v-loading="loading" class="student card">
    <div class="section-title">重点关注学生</div>
    <div class="operate mt20">
      <div class="filter">
        <div v-if="category === 2 || category === 3" class="selects-item">
          {{ category === 3 ? "选择对比考试：" : "选择指标：" }}
          <el-select
            v-model="otherType"
            style="width: 174px"
            placeholder="请选择"
            @change="otherSelectChange"
          >
            <el-option
              v-for="item in otherOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div v-if="category === 2" class="selects-item">
          临界分数：<el-input
            v-model="criticalInterval"
            style="width: 174px"
            oninput="value=value.replace(/[^\d]/g,'')"
            @blur="criticalInterval = $event.target.value"
          ></el-input>
          <el-button
            type="primary"
            style="margin-left: 10px"
            plain
            @click="getstatonlineoptionData"
            >确定</el-button
          >
        </div>

        <div v-if="category === 4 || category === 5" class="selects-item">
          {{ category === 5 ? "校排名后：" : "校排名前："
          }}<el-input
            v-model="topOrBackward"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 117px; margin-right: 24px"
            @blur="topOrBackward = $event.target.value"
          ></el-input>
          <el-button type="primary" @click="getTopOrBackward">确定</el-button>
        </div>
      </div>
      <el-radio-group v-model="category" size="small" @change="radioChange">
        <el-radio-button
          v-isUnionShow="{ field: 'topInfoOnlineVOs', type: 'length' }"
          :label="2"
          >临界生</el-radio-button
        >
        <el-radio-button :label="3">波动生</el-radio-button>
        <el-radio-button
          v-isUnionShow="'isSchoolDisplayReportClassRank'"
          :label="4"
          >拔尖生</el-radio-button
        >
        <el-radio-button
          v-isUnionShow="'isSchoolDisplayReportClassRank'"
          :label="5"
          >后进生</el-radio-button
        >
        <el-radio-button :label="1">跟踪生</el-radio-button>
      </el-radio-group>
    </div>
    <div class="chart-box">
      <exportBtn
        :request-func="requestFunc"
        :params="{
          id: $route.query.id,
          type: 2,
          classNum: params.classNum,
          subjectId: params.subjectId,
          criticalInterval: criticalInterval,
          topOrBackward: topOrBackward,
          upCompareId: otherType,
          downCompareId: otherType,
        }"
      >
        <div v-if="category === 2" class="title-box">
          <el-radio-group
            v-model="onlineType"
            size="small"
            @change="onlinesChange"
          >
            <el-radio-button :label="1">上线临界</el-radio-button>
            <el-radio-button :label="2">未上线临界</el-radio-button>
          </el-radio-group>
        </div>
        <div v-if="category === 3" class="title-box">
          <el-radio-group v-model="wave" size="small" @change="waveChange">
            <el-radio-button :label="1">上升波动</el-radio-button>
            <el-radio-button :label="2">下降波动</el-radio-button>
          </el-radio-group>
        </div>
      </exportBtn>
      <chart
        v-if="category === 3 && chartOptions"
        :data="chartOptions"
        is-ec-stat
        :height="500"
      />

      <div v-if="tableData.length" class="tableList">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="label" fixed label="姓名" align="center">
            <template slot-scope="{ row }">
              <span class="name" @click="toStudent(row)">{{ row.name }}</span>
            </template>
          </el-table-column>
          <div v-if="category === 1 || category === 2">
            <el-table-column prop="label" label="薄弱情况" align="center">
              <template slot-scope="{ row }">
                <span v-if="row.weak == 0">-</span>
                <span v-if="row.weak == 1">稍薄弱</span>
                <span v-if="row.weak == 2" class="seriousSubject"
                  >严重偏科
                </span>
              </template>
            </el-table-column>

            <el-table-column label="总分" prop="totalScore" align="center">
            </el-table-column>
            <el-table-column
              v-if="showCol('totalRank')"
              label="总分排名"
              prop="totalRank"
              align="center"
            >
            </el-table-column>
            <el-table-column :label="subjectName" prop="score" align="center">
            </el-table-column>
            <el-table-column
              v-if="showCol('rank')"
              :label="subjectName + '排名'"
              prop="rank"
              align="center"
            >
            </el-table-column>
          </div>
          <div v-if="category === 3">
            <el-table-column
              v-if="showCol('rank')"
              label="校排名增幅"
              align="center"
            >
              <template slot-scope="{ row }">
                <span v-if="row.rankWave > 0">
                  <i class="el-icon-top up"></i>
                  {{ row.rankWave }}
                </span>
                <span v-else-if="row.rankWave < 0">
                  <i class="el-icon-bottom down"></i>
                  {{ setAbs(row.rankWave) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="本次成绩" prop="score" align="center">
            </el-table-column>
            <el-table-column
              v-if="showCol('rank')"
              label="本次校排名"
              prop="rank"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="对比考试成绩"
              prop="compareScore"
              align="center"
            >
            </el-table-column>
            <el-table-column
              v-if="showCol('compareRank')"
              label="对比校排名"
              prop="compareRank"
              align="center"
            >
            </el-table-column>
          </div>
          <div v-if="category === 4 || category === 5">
            <el-table-column label="总分" prop="totalScore" align="center">
            </el-table-column>
            <el-table-column
              v-if="showCol('totalRank')"
              label="总分排名"
              prop="totalRank"
              align="center"
            >
            </el-table-column>
            <el-table-column :label="subjectName" prop="score" align="center">
            </el-table-column>
            <el-table-column
              v-if="showCol('rank')"
              :label="`${subjectName}排名`"
              prop="rank"
              align="center"
            >
            </el-table-column>
          </div>
        </el-table>
      </div>
      <no-data v-else></no-data>
      <div class="model-desc mt20">
        <div>{{ enums[category] }}</div>
        <div v-if="category === 3">
          注：图中横坐标为本次考试，纵坐标为对比考试；参考线上方的学生为上升波动学生，参考线下方的学生为下降波动的学生。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chart from "./chart.vue";
import { enums } from "./enum";
import exportBtn from "@/views/academic/components/exportBtn.vue";
import {
  getrkjsreportimportstudentTrack,
  getrkjsreportimportstudentWave,
  getrkjsreportimportstudentToporbackward,
  getrkjsreportimportstudentCritical,
  exportTeacherReport,
} from "@/core/api/academic/teacher";
import {
  UniongetrkjsreportimportstudentCritical,
  UniongetrkjsreportimportstudentWave,
  UniongetrkjsreportimportstudentToporbackward,
  UniongetrkjsreportimportstudentTrack,
} from "@/core/api/academic/unionReport";
import {
  getstatonlineoption,
  getstatcomparelist,
} from "@/core/api/academic/common";
export default {
  name: "PointStudent",
  components: {
    chart,
    exportBtn,
  },
  props: {
    params: {
      type: Object,
      default: () => {},
    },
    subjectName: {
      type: String,
      default: "",
    },
    topData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      requestFunc: exportTeacherReport,
      category: 2,
      chartOptions: {},
      enums: enums,
      type: 1,
      wave: 1,
      forward: 20,
      statId: this.$route.query.id,
      loading: false,
      tableData: [],
      subjectList: [],
      subjectData: [],
      otherOptions: [],
      waveData: {},
      otherType: "",
      chartData: [],
      topOrBackward: 20,
      interval: 20,
      examType: this.$route.query.examType,
      onlineType: 1,
      onlineData: {},
      io: {},
      criticalInterval: 10,
    };
  },

  mounted() {
    if (this.examType == 1 && !this.topData.topInfoOnlineVOs.length) {
      this.category = 3;
    } else {
      this.category = 2;
    }
    this.radioChange();
  },
  methods: {
    showCol(field) {
      if (this.examType == 1) {
        if (
          this.topData.isSchoolDisplayReportClassRank == 1 &&
          this.$route.query.isSchoolDisplayReportStudentRank == 1
        ) {
          return true;
        }
        return false;
      } else {
        if (this.tableData.length) {
          const result = this.tableData.every((item) => item[field] === null);
          return !result;
        }
        return false;
      }
    },
    toStudent(row) {
      const { href } = this.$router.resolve({
        path: "/academic/reportStat/studentReport",
        query: {
          ...this.$route.query,
          studentId: row.studentId,
          id: this.statId,
        },
      });
      window.open(href, "_blank");
    },
    getTopOrBackward() {
      if (!/(^[1-9]\d*$)/.test(this.topOrBackward)) {
        this.$message("排名应为正整数");
        return;
      } else {
        if (this.category == 4) {
          this.getrkjsreportimportstudentToporbackwardData(1);
        } else {
          this.getrkjsreportimportstudentToporbackwardData(2);
        }
      }
    },
    setAbs(val) {
      if (val) {
        return Math.abs(val);
      }
    },
    radioChange() {
      this.otherType = "";
      switch (this.category) {
        case 1:
          this.getrkjsreportimportstudentTrackData();
          break;
        case 2:
          // this.criticalInterval = this.topData.criticalInterval;
          this.getstatonlineoptionData();
          break;
        case 3:
          this.getstatcomparelistData();
          break;
        case 4:
          this.topOrBackward = 20;
          this.getrkjsreportimportstudentToporbackwardData(1);
          break;
        case 5:
          // this.topOrBackward = this.topData.segmentMaximum;
          this.topOrBackward = 20;
          this.getrkjsreportimportstudentToporbackwardData(2);
          break;
      }
    },
    onlinesChange() {
      this.getrkjsreportimportstudentCriticalData();
    },

    async getrkjsreportimportstudentTrackData() {
      this.tableData = [];
      this.loading = true;
      let res;
      if (this.examType == 1) {
        res = await UniongetrkjsreportimportstudentTrack({
          ...this.params,
          viewSchoolId: this.$route.query.schoolId,
          id: this.statId,
        });
      } else {
        res = await getrkjsreportimportstudentTrack({
          ...this.params,
          id: this.statId,
        });
      }

      this.loading = false;
      this.tableData = res.data.data;
    },
    async getrkjsreportimportstudentWaveData() {
      this.tableData = [];
      this.loading = true;
      let res;
      if (this.examType == 1) {
        res = await UniongetrkjsreportimportstudentWave({
          ...this.params,
          compareStatId: this.otherType,
          viewSchoolId: this.$route.query.schoolId,
          id: this.statId,
        });
      } else {
        res = await getrkjsreportimportstudentWave({
          ...this.params,
          compareStatId: this.otherType,
          id: this.statId,
        });
      }

      this.waveData = res.data.data;
      this.filterChartOptionsByWave([
        ...res.data.data.upStudents,
        ...res.data.data.downStudents,
      ]);
      if (res.data.data.upStudents.length) {
        this.tableData = res.data.data.upStudents;
        this.wave = 1;
      } else if (res.data.data.downStudents.length) {
        this.wave = 2;
        this.tableData = res.data.data.downStudents;
      }
      this.loading = false;
    },
    filterChartOptionsByWave(data) {
      if (data.length) {
        const compareRank = data.map((item) => item.compareRank);
        const rank = data.map((item) => item.rank);
        const maxCompareRank = Math.max(...compareRank);
        const maxRank = Math.max(...rank);
        const arr = data.map((item) => [
          item.rank,
          item.compareRank,
          item.name,
        ]);
        this.chartOptions = {
          tooltip: {
            axisPointer: {
              type: "shadow",
            },
            formatter: function (param) {
              return `${param.data[2]}:${param.data[0]},${param.data[1]}`;
            },
          },
          toolbox: {
            feature: {
              dataZoom: {},
            },
          },
          dataset: [
            {
              source: arr,
            },
            {
              transform: {
                type: "ecStat:regression",
                config: { method: "polynomial" },
              },
            },
          ],
          xAxis: {
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "rgba(0, 0, 0, 0.45)",
              },
            },
            axisLine: {
              show: true,
              lineStyle: { color: "rgba(0, 0, 0, 0.3)" },
            },
            splitLine: { show: false },
          },
          yAxis: {
            axisLine: {
              show: true,
              lineStyle: { color: "rgba(0, 0, 0, 0.3)" },
            },
            axisTick: {
              show: true,
              lineStyle: { color: "rgba(0, 0, 0, 0.3)" },
            },
            splitLine: { show: false },
          },
          series: [
            {
              symbolSize: 10,
              name: "考试排名对比",
              type: "scatter",
              datasetIndex: 0,
              markLine: {
                lineStyle: {
                  type: "solid",
                  color: "#FF9776",
                },
                tooltip: {
                  show: false,
                },
                data: [
                  [
                    {
                      coord: [0, 0],
                      symbol: "none",
                    },
                    {
                      coord: [maxRank, maxCompareRank],
                      symbol: "none",
                    },
                  ],
                ],
              },
              label: {
                show: true,
                position: "top",
                color: "inherit",
                fontSize: 9,
                formatter: function (params) {
                  return `${params.data[2]}`;
                },
              },
            },
          ],
        };
      } else {
        this.chartOptions = null;
      }
    },
    otherSelectChange() {
      if (this.category === 2) {
        this.getrkjsreportimportstudentCriticalData();
      } else if (this.category === 3) {
        this.getrkjsreportimportstudentWaveData();
      }
    },
    waveChange() {
      // console.log(this.wave);
      if (this.wave === 1) {
        this.tableData = this.waveData.upStudents || [];
      } else if (this.wave === 2) {
        this.tableData = this.waveData.downStudents || [];
        // console.log(this.topData);
      }
    },
    async getstatcomparelistData() {
      this.loading = true;
      const res = await getstatcomparelist({
        statId: this.statId,
        subjectId: this.params.subjectId,
      });
      this.otherOptions = res.data.data;
      if (res.data.data.length) {
        this.otherType = res.data.data[0].id;
        this.getrkjsreportimportstudentWaveData();
      } else {
        this.chartOptions = null;
        this.tableData = [];
        this.loading = false;
      }
    },
    async getrkjsreportimportstudentCriticalData() {
      if (/(^[1-9]\d*$)/.test(this.criticalInterval)) {
        this.loading = true;
        try {
          let res;
          if (this.examType == 1) {
            res = await UniongetrkjsreportimportstudentCritical({
              ...this.params,
              viewSchoolId: this.$route.query.schoolId,
              interval: this.criticalInterval,
              onlineId: this.otherType,
              id: this.statId,
            });
          } else {
            res = await getrkjsreportimportstudentCritical({
              ...this.params,
              interval: this.criticalInterval,
              onlineId: this.otherType,
              id: this.statId,
            });
          }

          this.loading = false;
          this.onlineData = res.data.data;
          const { upStudents, downStudents } = res.data.data;
          if (!this.tableData.length) {
            if (upStudents.length) {
              this.tableData = this.onlineData.upStudents;
            } else if (downStudents.length) {
              this.tableData = this.onlineData.downStudents;
            }
          } else {
            if (this.onlineType == 1) {
              this.tableData = this.onlineData.upStudents;
            } else if (this.onlineType == 2) {
              this.tableData = this.onlineData.downStudents;
            }
          }
        } catch {
          this.loading = false;
        }
      } else {
        this.$message.info("临界分数应为正整数");
      }
    },
    async getstatonlineoptionData() {
      const res = await getstatonlineoption({
        id: this.statId,
      });
      this.otherOptions = res.data.data;
      this.otherType = res.data.data.length && res.data.data[0].id;
      this.getrkjsreportimportstudentCriticalData();
    },
    async getrkjsreportimportstudentToporbackwardData(type) {
      this.loading = true;
      let res;
      if (this.examType == 1) {
        res = await UniongetrkjsreportimportstudentToporbackward({
          ...this.params,
          id: this.statId,
          viewSchoolId: this.$route.query.schoolId,
          topOrBackward: this.topOrBackward,
          type: type,
        });
      } else {
        res = await getrkjsreportimportstudentToporbackward({
          ...this.params,
          id: this.statId,
          topOrBackward: this.topOrBackward,
          type: type,
        });
      }

      this.loading = false;
      this.tableData = res.data.data;
    },
    getSubjectName(subjectId) {
      if (!Number(subjectId)) {
        return "总分";
      } else {
        let item = this.subjectList.find((item) => item.subjectId == subjectId);
        return item ? item.name : "-";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.student {
  .operate {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .filter {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .selects-item {
        margin-right: 24px;
      }
    }
  }

  .title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    // margin-bottom: 24px;
    .title {
      font-weight: bold;
      color: #101011;
      font-size: 14px;
    }
  }
}

.tag {
  background-color: #f2f8fe;
  text-indent: 2em;
  box-sizing: border-box;
  padding: 24px;
  margin: 24px 0;
  color: #737677;
  line-height: 1.5;
  font-size: 14px;
}

.name {
  text-decoration: underline;
  cursor: pointer;
  margin-right: 10px;
  color: #2474ed;
}

.up {
  color: #67c23a;
}

.down {
  color: #fc7070;
}

.seriousSubject {
  color: #fc7070;
}

::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background: #2474ed;
  border-color: #2474ed;

  &:hover {
    color: #fff;
  }
}

::v-deep .el-radio-button__inner {
  &:hover {
    color: #2474ed;
  }
}
</style>
