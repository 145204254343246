<template>
  <div ref="developAnalysis" v-loading="loading" class="card container">
    <div class="section-title">发展性分析</div>
    <examTransfer
      :class-num="params.classNum"
      :subject-id="params.subjectId"
      :stat-id="statId"
      :request-page="4"
      :exam-arr="examArr"
      @submit="getExamId"
    />
    <div class="operation mt20">
      <div>
        <div v-if="category !== 1 && category !== 6" class="selects-item">
          <div class="label">选择指标：</div>
          <el-select
            v-model="type"
            style="width: 174px"
            placeholder="请选择"
            @change="selectChange"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <el-radio-group v-model="category" size="small" @change="radioChange">
        <el-radio-button :label="1">{{
          examType == 1 ? "超均率（校）" : "超均率"
        }}</el-radio-button>
        <el-radio-button v-if="examType == 1" :label="6"
          >超均率（整体）</el-radio-button
        >
        <el-radio-button v-else :label="2">上线情况</el-radio-button>
        <el-radio-button :label="3">比率</el-radio-button>
        <el-radio-button :label="4">前N名</el-radio-button>
        <el-radio-button :label="5">其他指标</el-radio-button>
      </el-radio-group>
    </div>
    <div class="chart-box">
      <chart :data="lineOptions" :height="500" />
      <div class="model-desc mt20">
        <div>超均率：（班级平均分－年级平均分）÷年级平均分。</div>
        <div>上线情况：上线人数除以参与统计总人数的百分比。</div>
        <div>比率：满足比率要求的人数除以参与统计总人数的百分比。</div>
        <div>
          前N名：分数大于等于第N名分数的人数除以参与统计总人数的百分比。
        </div>
        <div>最高分：本场考试的最高分。</div>
        <div>最低分：本场考试的最低分。</div>
        <div>标准差：本场考试的标准差。</div>
      </div>
    </div>
  </div>
</template>

<script>
import chart from "./chart.vue";
import { examTypeOptions } from "@/core/util/constdata";
import { getrkjsreportdevelopanalysisV2 } from "@/core/api/academic/teacher";
import { UniongetrkjsreportdevelopanalysisV2 } from "@/core/api/academic/unionReport";
import examTransfer from "../../components/examTransfer.vue";
export default {
  name: "HeadReportDevelop",
  components: {
    chart,
    examTransfer,
  },
  props: {
    params: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      examTypeOptions: examTypeOptions,
      dialogVisible: false,
      examList: [],
      lineOptions: {},
      statId: this.$route.query.id,
      examType: this.$route.query.examType,
      settingName: "",
      category: 1,
      otherType: "",
      options: [],
      type: "",
      otherOptions: [],
      examName: "",
      checkedExam: "",
      loading: false,
      subjectList: [],
      io: {},
      loadingCompare: false,
      contrastStatId: null,
      examArr: [],
    };
  },
  watch: {
    params: {
      immediate: true,
      deep: true,
      handler() {
        this.$nextTick(() => {
          if (this.params.subjectId && this.params.classNum) {
            this.getData();
          }
        });
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.params.subjectId && Number(this.params.classNum)) {
        this.getData();
      }
    });
  },
  methods: {
    async getData() {
      try {
        let res;
        if (this.examType == 1) {
          res = await UniongetrkjsreportdevelopanalysisV2({
            id: this.statId,
            ...this.params,
            viewSchoolId: this.$route.query.schoolId,
            contrastStatId: this.contrastStatId,
          });
        } else {
          res = await getrkjsreportdevelopanalysisV2({
            id: this.statId,
            ...this.params,
            contrastStatId: this.contrastStatId,
          });
        }

        this.optionsData = res.data.data;
        this.examArr = res.data.data.stats;
        this.loading = false;
        this.radioChange();
      } catch {
        this.loading = false;
      }
    },
    getSubjectName(subjectId) {
      if (!Number(subjectId)) {
        return "总分";
      } else {
        let item = this.subjectList.find((item) => item.subjectId == subjectId);
        return item ? item.name : "-";
      }
    },
    getExamId(val) {
      this.contrastStatId = val.toString();
      this.getData();
    },

    selectChange() {
      if (this.category === 2) {
        this.setOnline();
      } else if (this.category === 3) {
        this.setRatio();
      } else if (this.category === 4) {
        this.setTop();
      } else if (this.category === 5) {
        this.setOther();
      }
    },
    radioChange() {
      switch (this.category) {
        case 1:
          this.setOverAverage();
          break;
        case 2:
          this.options = [...this.optionsData.onlines];
          this.type = this.options.length && this.options[0].id;
          this.setOnline();
          break;
        case 3:
          this.options = [...this.optionsData.ratios];
          this.type = this.options.length && this.options[0].id;
          this.setRatio();
          break;
        case 4:
          this.options = [...this.optionsData.topSections];
          this.type = this.options.length && this.options[0].id;
          this.setTop();
          break;
        case 5:
          this.type = 1;
          this.options = [
            { name: "最高分", id: 1 },
            { name: "最低分", id: 2 },
            { name: "标准差", id: 3 },
          ];
          this.setOther();
          break;
        case 6:
          this.setOverAverage();
          break;
      }
    },
    setOverAverage() {
      this.loading = true;
      let data = [...this.optionsData.stats].reverse();
      const xAxis = data.map((item) => item.name + "\n" + item.examDate);
      this.lineOptions = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#000",
            },
          },
        },
        title: {
          text:
            this.category == 1
              ? "图. 总分超均率（学校）"
              : "图. 总分超均率（整体）",
          textStyle: {
            fontWeight: "normal",
            fontSize: "14px",
          },
          left: "center",
        },
        grid: {
          top: "20%",
        },
        legend: {
          top: "10%",
        },
        yAxis: [
          {
            type: "value",
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: true },
          },
        ],
        xAxis: [
          {
            type: "category",
            data: xAxis,
            axisLine: { show: true, lineStyle: { color: "rgba(0,0,0,0.2)" } },
            axisLabel: { color: "rgba(0,0,0,0.2)" },
            axisTick: { show: false },
            splitLine: { show: false },
          },
        ],
        series: [
          {
            name: "超均率",
            data: data.map(
              (item) =>
                item.classData[
                  this.category == 1
                    ? "overAverageRate"
                    : "overAverageRateUnion"
                ] || 0
            ),
            type: "line",
            color: "#73DEB3",
            label: {
              show: true,
              textStyle: {
                color: "inherit",
                fontSize: 9,
              },
            },
          },
        ],
      };
      this.loading = false;
    },
    setOnline() {
      this.loading = true;

      let data = [...this.optionsData.stats].reverse();
      const xAxis = data.map((item) => item.name + "\n" + item.examDate);

      const classData = data.map((item) => {
        const itemData = item.classData.onlines.find(
          (it) => it.id == this.type
        );
        return itemData.proportion || 0;
      });
      const gradeData = data.map((item) => {
        const itemData = item.gradeData.onlines.find(
          (it) => it.id === this.type
        );
        return itemData.proportion || 0;
      });
      this.lineOptions = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#000",
            },
          },
        },
        title: {
          text: `图. 总分上线率（${
            this.options.find((item) => item.id === this.type).name
          }）`,
          textStyle: {
            fontWeight: "normal",
            fontSize: "14px",
          },
          left: "center",
        },
        grid: {
          top: "20%",
        },
        legend: {
          top: "10%",
        },
        yAxis: [
          {
            type: "value",
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: true },
          },
        ],
        xAxis: [
          {
            type: "category",
            data: xAxis,
            axisLine: { show: true, lineStyle: { color: "rgba(0,0,0,0.2)" } },
            axisLabel: { color: "rgba(0,0,0,0.2)" },
            axisTick: { show: false },
            splitLine: { show: false },
          },
        ],
        series: [
          {
            name: "班级",
            data: classData,
            type: "line",
            color: "#73DEB3",
            label: {
              show: true,
              textStyle: {
                color: "#73DEB3",
              },
            },
          },
          {
            name: "年级",
            data: gradeData,
            type: "line",
            color: "#FF6833",
            label: {
              show: true,
              textStyle: {
                color: "#FF6833",
              },
            },
          },
        ],
      };
      this.loading = false;
    },
    setRatio() {
      this.loading = true;
      let data = [...this.optionsData.stats].reverse();
      const xAxis = data.map((item) => item.name + "\n" + item.examDate);
      const lineData = data.flatMap((item) => {
        const itemData = item.classData.ratios.find(
          (item) => item.id === this.type
        );
        return itemData.proportion;
      });
      this.loading = false;
      this.lineOptions = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#000",
            },
          },
        },
        title: {
          text: `图. ${
            this.options.find((item) => item.id === this.type).name
          }比率（学校）`,
          textStyle: {
            fontWeight: "normal",
            fontSize: "14px",
          },
          left: "center",
        },
        grid: {
          top: "20%",
        },
        legend: {
          top: "10%",
        },
        yAxis: [
          {
            type: "value",
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: true },
          },
        ],
        xAxis: [
          {
            type: "category",
            data: xAxis,
            axisLine: { show: true, lineStyle: { color: "rgba(0,0,0,0.2)" } },
            axisLabel: { color: "rgba(0,0,0,0.2)" },
            axisTick: { show: false },
            splitLine: { show: false },
          },
        ],
        series: [
          {
            name: `${
              this.options.find((item) => item.id === this.type).name
            }比率`,
            data: lineData,
            type: "line",
            color: "#73DEB3",
            label: {
              show: true,
              textStyle: {
                color: "#73DEB3",
              },
            },
          },
        ],
      };
    },
    setTop() {
      this.loading = true;
      let data = [...this.optionsData.stats].reverse();
      const xAxis = data.map((item) => item.name + "\n" + item.examDate);
      const classData = data.flatMap((item) => {
        const itemData = item.classData.topSections.find(
          (it) => it.id === this.type
        );
        return itemData.total;
      });
      const gradeData = data.flatMap((item) => {
        const itemData = item.gradeData.topSections.find(
          (it) => it.id === this.type
        );
        return itemData.total;
      });

      this.lineOptions = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#000",
            },
          },
        },
        title: {
          text: `图. 总分（${
            this.options.find((item) => item.id === this.type).name
          }）`,
          textStyle: {
            fontWeight: "normal",
            fontSize: "14px",
          },
          left: "center",
        },
        grid: {
          top: "20%",
        },
        legend: {
          top: "10%",
        },
        yAxis: [
          {
            type: "value",
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: true },
          },
        ],
        xAxis: [
          {
            type: "category",
            data: xAxis,
            axisLine: { show: true, lineStyle: { color: "rgba(0,0,0,0.2)" } },
            axisLabel: { color: "rgba(0,0,0,0.2)" },
            axisTick: { show: false },
            splitLine: { show: false },
          },
        ],
        series: [
          {
            name: "班级",
            data: classData,
            type: "line",
            color: "#73DEB3",
            label: {
              show: true,
              textStyle: {
                color: "#73DEB3",
              },
            },
          },
          {
            name: "年级",
            data: gradeData,
            type: "line",
            color: "#FF6833",
            label: {
              show: true,
              textStyle: {
                color: "#FF6833",
              },
            },
          },
        ],
      };
      this.loading = false;
    },
    setOther() {
      this.loading = true;
      let data = [...this.optionsData.stats].reverse();

      let field = null;
      if (this.type === 1) {
        field = "highestScore";
      } else if (this.type === 2) {
        field = "lowestScore";
      } else if (this.type === 3) {
        field = "standardDeviation";
      }
      const classData = data.map((item) => item.classData[field]);
      const gradeData = data.map((item) => item.gradeData[field]);
      const xAxis = data.map((item) => item.name + "\n" + item.examDate);
      this.lineOptions = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#000",
            },
          },
        },
        title: {
          text: `图. ${
            this.options.find((item) => item.id === this.type).name
          }`,
          textStyle: {
            fontWeight: "normal",
            fontSize: "14px",
          },
          left: "center",
        },
        grid: {
          top: "20%",
        },
        legend: {
          top: "10%",
        },
        yAxis: [
          {
            type: "value",
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: true },
          },
        ],
        xAxis: [
          {
            type: "category",
            data: xAxis,
            axisLine: { show: true, lineStyle: { color: "rgba(0,0,0,0.2)" } },
            axisLabel: { color: "rgba(0,0,0,0.2)" },
            axisTick: { show: false },
            splitLine: { show: false },
          },
        ],
        series: [
          {
            name: "班级",
            data: classData,
            type: "line",
            color: "#73DEB3",
            label: {
              show: true,
              textStyle: {
                color: "#73DEB3",
              },
            },
          },
          {
            name: "年级",
            data: gradeData,
            type: "line",
            color: "#FF6833",
            label: {
              show: true,
              textStyle: {
                color: "#FF6833",
              },
            },
          },
        ],
      };
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  .selects-item {
    display: flex;
    align-items: center;
  }

  .operation {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .tag {
    font-size: 14px;
    border: 1px dashed #2474ed;
    color: #737677;
    background-color: #f2f8fe;
    box-sizing: border-box;
    padding: 12px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      margin-bottom: 12px;
    }

    & :last-child {
      margin: 0;
    }
  }
}

::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background: #2474ed;
  border-color: #2474ed;

  &:hover {
    color: #fff;
  }
}

::v-deep .el-radio-button__inner {
  &:hover {
    color: #2474ed;
  }
}
</style>
