<template>
  <div class="headReport">
    <div ref="titleBox" class="topCard">
      <tag />
      <div class="title-box">
        <div v-if="$route.query.ruleId > 0" class="new-exam-tag">新高考</div>

        <div class="title">
          {{ topData.name }}
          <div :class="['sub-tag', topData.type == 99 ? 'other' : 'general']">
            {{ getExamType(topData.type) }}
          </div>
        </div>
      </div>
      <div class="back" @click="back">
        <i class="el-icon-arrow-left"></i>返回
      </div>
      <div class="sub-title">
        <div>
          {{ topData.examDate }}&nbsp;&nbsp;&nbsp;&nbsp;<span v-if="level == 2"
            >初</span
          ><span v-if="level == 3">高</span>{{ topData.year }}级
        </div>
      </div>

      <exportBtn
        btn-type="primary"
        text="导出全部报表"
        :request-func="requestFunc"
        :params="{
          id: $route.query.id,
          type: 0,
          subjectId: search.subjectId,
          classNum: search.classNum,
        }"
      >
        <div class="model-title">任课老师报告</div>
      </exportBtn>

      <div class="subjects mt10">
        <el-select
          v-model="search.subjectId"
          placeholder="科目"
          @change="selectChange"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="subject-tab">
        <el-tabs v-model="search.classNum" @tab-click="handleClick">
          <el-tab-pane
            v-for="(item, index) in classList"
            :key="index"
            :label="item.name"
            :name="item.name"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <div v-loading="loading" class="dashboard">
        <div class="class-scorebox bg-lightgreen">
          <div class="leftpart">
            <p class="ft-green">{{ topData.classAverage }}</p>
            <span>平均分NO.{{ topData.classRank }}</span>
          </div>
          <div class="rightpart">
            <p>
              年级：<span>{{ topData.gradeAverage }}</span>
              <i class="el-icon-check"></i>
            </p>
          </div>
        </div>
        <div
          v-for="(item, index) in topData.topInfoOnlineVOs"
          :key="index"
          class="class-scorebox bg-lightblue"
        >
          <div class="leftpart">
            <p class="ft-green">{{ item.classProportion }}</p>
            <span>{{ item.name }}</span>
          </div>
          <div class="rightpart">
            <p>
              年级：<span>{{ item.gradeProportion }}</span>
              <i class="el-icon-check"></i>
            </p>
          </div>
        </div>
      </div>
    </div>
    <template v-if="search.subjectId && search.classNum && topData.year">
      <competitive
        id="classCompetitive"
        :key="search.subjectId + search.classNum + 'classCompetitive'"
        :params="search"
        :top-data="topData"
      />
      <branch
        id="branch"
        :key="search.subjectId + search.classNum + 'branch'"
        :params="search"
        :top-data="topData"
      />
      <student
        id="student"
        :key="search.subjectId + search.classNum + 'student'"
        :params="search"
        :subject-name="subjectName"
        :top-data="topData"
      />
      <developAnalysis
        id="developAnalysis"
        :key="search.subjectId + search.classNum + 'developAnalysis'"
        :params="search"
        :top-data="topData"
      />
      <paperAnalysis
        v-if="!search.merge"
        id="paperAnalysis"
        :key="search.subjectId + search.classNum + 'paperAnalysis'"
        :params="search"
        :top-data="topData"
      />
    </template>
    <page-nav :menu-list="menuList" />
  </div>
</template>

<script>
import exportBtn from "@/views/academic/components/exportBtn.vue";
import { examTypeOptions } from "@/core/util/constdata";
import tag from "../components/tag.vue";
import competitive from "./components/competitive.vue";
import branch from "./components/branch.vue";
import paperAnalysis from "./components/paperAnalysis.vue";
import student from "./components/student.vue";
import developAnalysis from "./components/developAnalysis";
import pageNav from "../components/pageNav.vue";
import {
  getstatclassoption,
  getstatsubjectoption,
} from "@/core/api/academic/common";
import { fileDownloadByUrl } from "@/core/util/util";
import {
  getrenkejiaoshireporttopinfo,
  downloadrenkejiaoshipdfreport,
  exportTeacherReport,
} from "@/core/api/academic/teacher";
import { Uniongetrenkejiaoshireporttopinfo } from "@/core/api/academic/unionReport";
export default {
  name: "HeadReport",
  components: {
    exportBtn,
    tag,
    competitive,
    branch,
    paperAnalysis,
    student,
    developAnalysis,
    pageNav,
  },
  data() {
    return {
      requestFunc: exportTeacherReport,
      menuList: [
        {
          name: "班级竞争力",
          id: "classCompetitive",
        },
        {
          name: "成绩分布",
          id: "branch",
        },
        {
          name: "重点关注学生",
          id: "student",
        },

        {
          name: "发展性分析",
          id: "developAnalysis",
        },
        {
          name: "试卷分析",
          id: "paperAnalysis",
        },
      ],
      statId: this.$route.query.id,
      level: this.$route.query.level,
      classList: [],
      topData: "",
      subjectId: "",
      loading: false,
      search: {
        subjectId: null,
        classNum: null,
        merge: true,
      },
      examType: this.$route.query.examType,
      options: [],
      subjectName: "",
    };
  },
  mounted() {
    this.getConditionList();
  },

  methods: {
    async selectChange() {
      let data = this.options.find((item) => item.id == this.search.subjectId);
      this.subjectName = data.name;
      this.search.merge = data.type === 2 || data.type === 3;
      const classRes = await this.getClassList();
      this.classList = classRes.data.data;
      this.search.classNum = classRes.data.data[0].name;
      this.getrenkejiaoshireporttopinfoData();
    },
    async getConditionList() {
      const subjectRes = await this.getSubjectList();
      this.search.subjectId = subjectRes.data.data[0].id;
      this.subjectName = subjectRes.data.data[0].name;
      this.options = subjectRes.data.data;
      const classRes = await this.getClassList();
      this.classList = classRes.data.data;
      this.search = {
        classNum: classRes.data.data[0].name,
        subjectId: subjectRes.data.data[0].id,
        merge:
          subjectRes.data.data[0].type === 2 ||
          subjectRes.data.data[0].type === 3,
      };
      this.getrenkejiaoshireporttopinfoData();
    },
    getClassList() {
      return getstatclassoption({
        id: this.statId,
        subjectId: this.search.subjectId,
        requestPage: 4,
        viewSchoolId: this.$route.query.schoolId,
      });
    },
    getSubjectList() {
      return getstatsubjectoption({
        id: this.statId,
        hasTotal: 0,
        requestPage: 4,
        viewSchoolId: this.$route.query.schoolId,
      });
    },
    async getrenkejiaoshireporttopinfoData() {
      this.$store.commit("report/SET_TOPDATA", null);
      this.loading = true;
      let res;
      if (this.examType == 1) {
        res = await Uniongetrenkejiaoshireporttopinfo({
          viewSchoolId: this.$route.query.schoolId,
          id: this.statId,
          ...this.search,
        });
      } else {
        res = await getrenkejiaoshireporttopinfo({
          id: this.statId,
          ...this.search,
        });
      }

      this.topData = res.data.data;
      this.$store.commit("report/SET_TOPDATA", res.data.data);
      this.loading = false;
    },
    handleClick() {
      this.getrenkejiaoshireporttopinfoData();
    },
    back() {
      this.$router.go(-1);
    },
    async downLoad() {
      const res = await downloadrenkejiaoshipdfreport({
        id: this.statId,
        ...this.search,
      });
      if (res.data.data.url) {
        fileDownloadByUrl(res.data.data);
      }
    },
    getExamType(type) {
      if (type) {
        const data = examTypeOptions.find((item) => item.value === type);
        return data.label;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.headReport {
  width: 1200px;
  margin: 0 auto;

  .subjects {
    display: flex;
    justify-content: center;
  }
}

::v-deep .el-tabs__nav-wrap::after {
  display: none;
}

::v-deep .el-tabs__active-bar {
  display: none;
}

::v-deep .el-tabs__item {
  padding: 0 24px !important;
  height: 32px;
  line-height: 32px;
  margin: 0 18px;
  box-sizing: border-box;
}

::v-deep .el-tabs {
  .is-active {
    border: 1px solid #3f87f4;
    border-radius: 16px;
    background-color: #f2faff;
  }
}

::v-deep .el-icon-arrow-left {
  &::before {
    font-size: 24px;
    line-height: 24px;
  }
}

::v-deep .el-icon-arrow-right {
  &::before {
    font-size: 24px;
    line-height: 24px;
  }
}
</style>
