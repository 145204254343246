<template>
  <div ref="bar" :style="{ width: '100%', height: height }"></div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "Bar",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    height: {
      type: String || Number,
      default: "90%",
    },
  },
  data() {
    return {
      option: {
        legend: {
          data: ["Allocated Budget", "Actual Spending"],
        },
        tooltip: {
          backgroundColor: "rgba(0,0,0,0.7)",
          borderColor: "rgba(0,0,0,0.7)",
          textStyle: {
            color: "#fff",
          },
        },
        color: ["#76B2EE", "#FA8962"],
        radar: {
          indicator: [
            { name: "Sales", max: 6500 },
            { name: "Administration", max: 16000 },
            { name: "Information Technology", max: 30000 },
            { name: "Customer Support", max: 38000 },
            { name: "Development", max: 52000 },
            { name: "Marketing", max: 25000 },
          ],
        },
        series: [
          {
            name: "Budget vs spending",
            type: "radar",
            data: [
              {
                value: [4200, 3000, 20000, 35000, 50000, 18000],
                name: "Allocated Budget",
              },
              {
                value: [5000, 14000, 28000, 26000, 42000, 21000],
                name: "Actual Spending",
              },
            ],
          },
        ],
      },
    };
  },
  watch: {
    data(val) {
      this.initChart(val);
    },
  },

  methods: {
    initChart(val) {
      let charts = echarts.getInstanceByDom(this.$refs.bar);
      if (!charts) {
        charts = echarts.init(this.$refs.bar, null, { devicePixelRatio: 2.5 });
      }
      this.option = { ...this.option, ...val };
      charts.setOption(this.option, true);
      setTimeout(() => {
        window.onresize = () => {
          charts.resize();
        };
      }, 100);
    },
  },
};
</script>
<style lang="scss" scoped></style>
