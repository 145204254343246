<template>
  <div ref="paperAnalysis" v-loading="tableLoading" class="subject card">
    <exportBtn
      :request-func="requestFunc"
      :params="{
        id: $route.query.id,
        type: 3,
        subjectId: params.subjectId,
        classNum: params.classNum,
      }"
    >
      <div class="section-title">试卷分析</div>
    </exportBtn>
    <div v-if="!params.merge" class="operate">
      <div class="section-sub-title">应答情况分析</div>
      <div class="radios">
        <el-radio-group
          v-model="status"
          style="margin-right: 24px"
          size="small"
          @change="statusChange"
        >
          <el-radio-button :label="1">图</el-radio-button>
          <el-radio-button :label="2">表</el-radio-button>
        </el-radio-group>
        <el-radio-group
          v-model="category"
          size="small"
          @change="categoryChange"
        >
          <el-radio-button
            v-if="topData && topData.hasKnowledge == 1"
            :label="1"
            >知识点</el-radio-button
          >
          <!-- <el-radio-button :label="2">考察能力</el-radio-button> -->
          <el-radio-button :label="3">题目</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div v-if="!params.merge" class="chart-box">
      <div v-if="status === 1 && category === 1">
        <radar v-if="pointOptions" :data="pointOptions" height="400px" />
        <no-data v-else />
      </div>
      <div v-if="status === 1 && category === 2">
        <radar v-if="tableData.length" :data="abilityOptions" height="400px" />
        <no-data v-else />
      </div>
      <div
        v-if="
          (status === 2 && category === 1) || (status === 2 && category === 2)
        "
      >
        <el-table
          v-if="tableData.length"
          ref="elTable"
          :data="tableData"
          border
          style="width: 100%; margin-bottom: 24px"
        >
          <el-table-column
            v-if="category === 1"
            prop="knowledgeName"
            label="知识点"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="score"
            label="满分分值"
            width="120"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="countQuestion"
            label="题量"
            width="80"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="questionOrders"
            width="80"
            label="题号"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="averageScore"
            width="100"
            label="平均分"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="scoreRate"
            width="100"
            label="得分率"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="schoolScoreRate"
            width="120"
            label="校得分率"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="countRight"
            width="120"
            label="满分人数"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="countZero"
            width="120"
            label="零分人数"
            align="center"
          >
          </el-table-column>
        </el-table>
        <div v-if="old.length > 10" class="btns">
          <el-button type="text" @click="more">{{
            showMore ? "收起" : "查看更多"
          }}</el-button>
        </div>
        <no-data v-if="!old.length" />
      </div>

      <div v-if="status === 1 && category === 3">
        <bar-chart
          v-if="tableData.length"
          :data="barChartOptions"
          :height="500"
        />
        <no-data v-else />
      </div>
      <div v-if="status === 2 && category === 3">
        <el-table
          v-if="tableData.length"
          ref="elTable2"
          :data="tableData"
          border
          style="width: 100%"
        >
          <el-table-column
            prop="questionOrder"
            label="题目"
            width="80"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="score"
            label="满分分值"
            width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="averageScore"
            label="平均分"
            width="70"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="scoreRate"
            label="得分率"
            width="70"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="schoolScoreRate"
            label="得分率（年级）"
            align="center"
            width="90"
          >
            <template slot-scope="{ row }">
              <span
                :class="row.scoreRate < row.schoolScoreRate ? 'ft-red' : ''"
                >{{ row.schoolScoreRate }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="countZero"
            label="零分人数"
            width="120"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="countRight"
            label="满分人数"
            width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column label="客观题选项分布" prop="options" align="center">
            <template slot-scope="{ row }">
              <div
                v-for="(item, index) in row.options"
                :key="'optionDistribution' + index"
                class="flex"
              >
                <div class="label">{{ item.item }}：</div>
                <!-- <el-progress
                  :percentage="
                    getPercent(item.total, row.countWrong + row.countRight)
                  "
                  :format="item.total"
                ></el-progress> -->
                <div class="out">
                  <div
                    class="progress"
                    :style="{
                      width:
                        getPercent(
                          item.total,
                          row.countWrong + row.countRight,
                          row.options
                        ) + 'px',
                    }"
                  ></div>
                </div>
                <div class="total">{{ item.total }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="countWrong"
            label="答错人数"
            width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="wrongStudents"
            label="答错名单"
            align="left"
            width="200"
          >
            <template slot-scope="{ row }">
              <div class="namList">
                <template v-for="(item, index) in row.wrongStudents">
                  <span
                    v-if="showStudentList(index, row)"
                    :key="index"
                    :class="item.type === 1 ? 'green' : 'red'"
                    @click="studentDetail(item)"
                    >{{ item.name }}</span
                  >
                </template>
                <el-button
                  v-if="row.wrongStudents.length > 5"
                  type="text"
                  @click="moreStudent(row)"
                  >{{ row.showMore ? "收起" : "查看更多" }}</el-button
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="pointsOptions"
            prop="knowledgeNames"
            label="知识点"
            width="100"
            align="center"
          >
          </el-table-column>
          <!-- <el-table-column prop="ability" label="考察能力" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.ability === 1">易</span>
            <span v-if="row.ability === 2">中</span>
            <span v-if="row.ability === 3">难</span>
          </template>
        </el-table-column> -->
        </el-table>
        <div v-if="old.length > 10" class="btns">
          <el-button type="text" @click="more">{{
            showMore ? "收起" : "查看更多"
          }}</el-button>
        </div>
        <no-data v-if="old.length == 0" />
        <div class="tag">
          注：标红得分率表示班级得分率低于校得分率（或整体得分率）
        </div>
      </div>
    </div>
    <div v-if="!params.merge" class="section-sub-title">学生应答反馈</div>
    <header-table
      v-loading="loading"
      :data="headerTableData"
      :top-data="topData"
    />
    <div v-if="!params.merge" class="tag">
      注：客观题显示的是学生的答错选项，主观题显示的是学生失分分值
    </div>
  </div>
</template>

<script>
import radar from "./radar.vue";
import barChart from "./chart.vue";
import headerTable from "./headerTable.vue";
import {
  getrkjsreportpaperanalyKnowledge,
  getrkjsreportpaperanalyQuestion,
  getrkjsreportpaperanalyStudent,
  exportTeacherReport,
} from "@/core/api/academic/teacher";
import {
  UniongetrkjsreportpaperanalyKnowledge,
  UniongetrkjsreportpaperanalyQuestion,
  UniongetrkjsreportpaperanalyStudent,
} from "@/core/api/academic/unionReport";
import exportBtn from "@/views/academic/components/exportBtn.vue";
export default {
  name: "PaperAnalysis",

  components: {
    radar,
    barChart,
    headerTable,
    exportBtn,
  },
  props: {
    params: {
      type: Object,
      default: () => {},
    },
    topData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      subjectId: "",
      status: 1,
      requestFunc: exportTeacherReport,
      pointsOptions: null,
      category: 3,
      pointOptions: {},
      tableData: [{ label: "1" }],
      abilityOptions: {},
      barChartOptions: {},
      examType: this.$route.query.examType,
      statId: this.$route.query.id,
      subjectList: [],
      abilityArr: ["易", "中", "难 "],
      headerTableData: [],
      unobserve: false,
      io: {},
      loading: false,
      tableLoading: false,
      old: [],
      showMore: false,
    };
  },

  mounted() {
    if (!this.params.merge) {
      this.category = 3;
      this.categoryChange();
      this.getrkjsreportpaperanalyStudentData();
    } else {
      this.abilityOptions = null;
      this.barChartOptions = null;
      this.headerTableData = [];
    }
  },
  methods: {
    moreStudent(item) {
      if (item.showMore) {
        this.$set(item, "showMore", false);
      } else {
        this.$set(item, "showMore", true);
      }
    },
    showStudentList(index, row) {
      if (row.showMore) {
        return true;
      } else if (!row.showMore && index < 5) {
        return true;
      } else {
        return false;
      }
    },
    getPercent(num, total, options) {
      let totalNumber = options.reduce((total, item) => {
        return total + Number(item.total);
      }, 0);
      if (!num || !total) {
        return 0;
      } else {
        return (num / totalNumber) * 80;
      }
    },
    categoryChange() {
      if (this.category === 1) {
        this.getrkjsreportpaperanalyKnowledgeData();
      } else if (this.category === 3) {
        this.getrkjsreportpaperanalyQuestionData();
      }
    },
    statusChange() {
      this.categoryChange();
    },
    formatProgress() {
      return "";
    },
    handleClick() {
      this.categoryChange();
    },
    async getrkjsreportpaperanalyKnowledgeData() {
      this.tableLoading = true;
      let res;
      if (this.examType == 1) {
        res = await UniongetrkjsreportpaperanalyKnowledge({
          ...this.params,
          viewSchoolId: this.$route.query.schoolId,
          id: this.statId,
        });
      } else {
        res = await getrkjsreportpaperanalyKnowledge({
          ...this.params,
          id: this.statId,
        });
      }

      const resData = res.data.data;
      if (res.data.data.length > 0) {
        this.pointOptions = {
          color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
          radar: {
            shape: "circle",
            indicator: resData.map((item) => {
              return { name: item.knowledgeName, max: 100 };
            }),
          },

          series: [
            {
              name: "知识点",
              type: "radar",
              data: [
                {
                  value: resData.map((item) => item.scoreRate) || [],
                  name: "班级得分率",
                },
                {
                  value: resData.map((item) => item.schoolScoreRate) || [],
                  name: "年级得分率",
                },
              ],
            },
          ],
        };
        this.tableData = resData.slice(0, 10);
        this.showMore = false;
        this.old = [...resData];
        this.tableLoading = false;
      } else {
        this.pointOptions = null;
        this.tableData = [];
        this.categoryChange();
        this.tableLoading = false;
      }

      this.$nextTick(() => {
        if (this.$refs.elTable) {
          this.$refs.elTable.doLayout();
        }
        if (this.$refs.elTable2) {
          this.$refs.elTable2.doLayout();
        }
      });
    },
    more() {
      this.showMore = !this.showMore;
      this.tableData = this.showMore ? [...this.old] : this.old.slice(0, 10);
    },
    async getrkjsreportpaperanalyQuestionData() {
      this.tableLoading = true;
      let res;
      if (this.examType == 1) {
        res = await UniongetrkjsreportpaperanalyQuestion({
          viewSchoolId: this.$route.query.schoolId,
          ...this.params,
          id: this.statId,
        });
      } else {
        res = await getrkjsreportpaperanalyQuestion({
          ...this.params,
          id: this.statId,
        });
      }

      this.tableLoading = false;
      let xAxis = res.data.data.map((item) => item.questionOrder);
      this.barChartOptions = {
        yAxis: [
          {
            type: "value",
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: true },
          },
        ],
        legend: { itemGap: 30 },
        color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.7)",
          borderColor: "rgba(0,0,0,0.7)",
          textStyle: {
            color: "#fff",
          },
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(0, 0, 0, 0.1)",
            },
          },

          valueFormatter: function (val) {
            return Math.abs(val);
          },
        },
        grid: {
          bottom: xAxis.length > 25 ? "25%" : "8%",
          containLabel: true,
        },
        dataZoom: [
          //滚动条
          {
            show: xAxis.length > 25,
            type: "slider",
            realtime: true,
            startValue: 0, // 重点
            // 重点-dataX.length表示x轴数据长度
            endValue: xAxis.length - (xAxis.length - 25),
            xAxisIndex: [0],
            bottom: "5%",
            left: "center",
          },
        ],
        xAxis: [
          {
            type: "category",
            data: xAxis,
            axisLine: { show: true, lineStyle: { color: "rgba(0,0,0,0.2)" } },
            axisLabel: {
              color: "rgba(0,0,0,0.2)",
              show: true,
              textStyle: {
                color: "rgba(0, 0, 0, 0.45)",
              },
              rotate: 25,
            },
            axisTick: { show: false },
            splitLine: { show: false },
          },
        ],
        series: [
          {
            name: "班级得分率", // 不同条柱
            type: "bar",
            label: {
              show: true,
              textStyle: {
                color: "inherit",
                fontSize: 9,
              },
              position: "top",
            },
            data: res.data.data.map((item) => item.scoreRate),
            barMaxWidth: 40,
          },
          {
            name: "年级得分率",
            type: "line",
            label: {
              position: "top",
              show: false,
              textStyle: {
                color: "#FF5233",
              },
            },
            data: res.data.data.map((item) => item.schoolScoreRate),
            color: "#FF5233",
          },
        ],
      };
      this.tableData = this.showMore
        ? res.data.data
        : res.data.data.slice(0, 10);
      this.showMore = false;
      this.old = [...res.data.data];
      this.$nextTick(() => {
        if (this.$refs.elTable) {
          this.$refs.elTable.doLayout();
        }
        if (this.$refs.elTable2) {
          this.$refs.elTable2.doLayout();
        }
      });
    },
    async getrkjsreportpaperanalyStudentData() {
      this.loading = true;
      let res;
      if (this.examType == 1) {
        res = await UniongetrkjsreportpaperanalyStudent({
          viewSchoolId: this.$route.query.schoolId,
          ...this.params,
          id: this.statId,
        });
      } else {
        res = await getrkjsreportpaperanalyStudent({
          ...this.params,
          id: this.statId,
        });
      }

      this.loading = false;
      this.headerTableData = res.data.data;
    },
    studentDetail(item) {
      const { href } = this.$router.resolve({
        path: "/academic/reportStat/studentReport",
        query: {
          studentId: item.studentId,
          ...this.$route.query,
          id: this.statId,
        },
      });
      window.open(href, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
.subject {
  .title {
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 24px;
  }

  .operate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0;
    .title {
      color: #333333;
      font-size: 16px;
      font-weight: bold;
    }
  }
  .tag {
    height: 60px;
    background-color: #f2f8fe;
    line-height: 60px;
    box-sizing: border-box;
    padding: 0 12px;
    margin: 24px 0;
    color: #737677;
    font-size: 14px;
  }
}
::v-deep .el-tabs__nav-wrap::after {
  display: none;
}
::v-deep .el-tabs__active-bar {
  display: none;
}
::v-deep .el-tabs__item {
  padding: 0 24px !important;
  height: 32px;
  line-height: 32px;
  margin: 0 18px;
  box-sizing: border-box;
}
::v-deep .el-tabs {
  .is-active {
    border: 1px solid #3f87f4;
    border-radius: 16px;
    background-color: #f2faff;
  }
}
.flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  .label {
    width: 50px;
    text-align: left;
  }
  .out {
    width: 80px;
    background-color: #eee;
    height: 8px;
    .progress {
      height: 8px;
      background-color: #63a8ec;
    }
  }

  .total {
    width: 60px;
    text-align: left;
    margin-left: 3px;
  }
}
.el-progress {
  width: 60%;
}
::v-deep .el-progress-bar__outer {
  border-radius: 0;
}
::v-deep .el-progress-bar__inner {
  border-radius: 0;
}

.namList {
  cursor: pointer;
  span {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
.green {
  color: #67c23a;
  text-decoration: underline;
}
.red {
  color: #f56c6c;
  text-decoration: underline;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background: #2474ed;
  border-color: #2474ed;
  &:hover {
    color: #fff;
  }
}
::v-deep .el-radio-button__inner {
  &:hover {
    color: #2474ed;
  }
}
.btns {
  margin: 10px 0;
  text-align: center;
}
</style>
