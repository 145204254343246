<template>
  <div ref="branch" v-loading="loading" class="branch card">
    <div class="section-title">成绩分布</div>
    <div class="operate mt20">
      <div v-if="type === 1" class="filter">
        <div class="label" style="margin-left: 24px">分数段区间设置：</div>
        <div class="filter-item">
          <div class="filter-item-label">上限</div>
          <el-input
            v-model.number="search.high"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 72px"
            @blur="search.high = $event.target.value"
          ></el-input>
          <div class="unit">分</div>
        </div>
        <div class="filter-item">
          <div class="filter-item-label">下限</div>
          <el-input
            v-model.number="search.low"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 72px"
            @blur="search.low = $event.target.value"
          ></el-input>
          <div class="unit">分</div>
        </div>
        <div class="filter-item">
          <div class="filter-item-label">间隔</div>
          <el-input
            v-model.number="search.interval"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 72px"
            @blur="search.interval = $event.target.value"
          ></el-input>
          <div class="unit">分</div>
        </div>
        <el-button
          type="primary"
          plain
          @click="getrkjsreportscoredistributionScoreData"
          >确定</el-button
        >
      </div>
      <div v-else class="filter">
        <div class="label">排名区间设置：</div>
        <div class="filter-item" style="margin-right: 5px">
          <el-input
            v-model.number="search.high"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 72px"
            @blur="search.high = $event.target.value"
          ></el-input>
          <div class="unit">名</div>
        </div>
        <div class="filter-item">
          <div class="filter-item-label">—</div>
          <el-input
            v-model.number="search.low"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 72px"
            @blur="search.low = $event.target.value"
          ></el-input>
          <div class="unit">名</div>
        </div>
        <div class="filter-item">
          <div class="filter-item-label">间隔</div>
          <el-input
            v-model.number="search.interval"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 72px"
            @blur="search.interval = $event.target.value"
          ></el-input>
          <div class="unit">名</div>
        </div>
        <el-button
          type="primary"
          plain
          :disabled="rankBtnDisabled"
          @click="getrkjsreportscoredistributionRankData"
          >确定</el-button
        >
      </div>
      <div class="raido">
        <el-radio-group v-model="type" size="small" @change="raidoChange">
          <el-radio-button :label="1">分数分布</el-radio-button>
          <el-radio-button
            v-isUnionShow="'isSchoolDisplayReportClassRank'"
            :label="2"
            >排名分布</el-radio-button
          >
        </el-radio-group>
      </div>
    </div>
    <div class="chart-box">
      <bar-chart v-if="chartOptions" :data="chartOptions" :height="500" />
      <no-data v-else></no-data>
      <div v-if="type === 1" class="tag">
        注：红色柱子表示班级平均分所在分数段
      </div>
    </div>
  </div>
</template>

<script>
import barChart from "./chart.vue";
import {
  getrkjsreportscoredistributionScore,
  getrkjsreportscoredistributionRank,
} from "@/core/api/academic/teacher";
import {
  UniongetrkjsreportscoredistributionScore,
  UniongetrkjsreportscoredistributionRank,
} from "@/core/api/academic/unionReport";

export default {
  name: "HeadReportBranch",
  components: {
    barChart,
  },
  props: {
    params: {
      type: Object,
      default: () => {},
    },
    topData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      search: {
        subjectId: "",
        high: 150,
        low: 50,
        interval: 10,
      },
      type: 1,
      options: [],
      chartOptions: {},
      heatOptions: {},
      statId: this.$route.query.id,
      io: {},
      loading: false,
      examType: this.$route.query.examType,
      rankBtnDisabled: false,
    };
  },

  mounted() {
    this.type = 1;
    this.raidoChange();
  },
  methods: {
    raidoChange() {
      this.loading = true;
      if (this.type == 1) {
        this.search.high = this.topData.segmentMaximum;
        this.search.low = this.topData.segmentMinimum;
        this.search.interval = this.topData.segmentGap;
        this.getrkjsreportscoredistributionScoreData();
      } else {
        this.search.high = this.topData.rankMaximum || null;
        this.search.low = this.topData.rankMinimum || null;
        this.search.interval = this.topData.rankGap || null;
        this.getrkjsreportscoredistributionRankData();
      }
    },

    async getrkjsreportscoredistributionScoreData() {
      if (this.search.interval && this.search.high && this.search.low) {
        try {
          this.loading = true;
          let res;
          if (this.examType == 1) {
            res = await UniongetrkjsreportscoredistributionScore({
              viewSchoolId: this.$route.query.schoolId,
              ...this.search,
              ...this.params,
              id: this.statId,
            });
          } else {
            res = await getrkjsreportscoredistributionScore({
              ...this.search,
              ...this.params,
              id: this.statId,
            });
          }

          this.loading = false;
          this.chartOptions = {
            tooltip: {
              trigger: "axis",
              backgroundColor: "rgba(0,0,0,0.7)",
              borderColor: "rgba(0,0,0,0.7)",
              textStyle: {
                color: "#fff",
              },
              axisPointer: {
                type: "shadow",
                shadowStyle: {
                  color: "rgba(0, 0, 0, 0.1)",
                },
              },

              valueFormatter: function (val) {
                return Math.abs(val);
              },
            },
            legend: {
              itemGap: 30,
            },
            dataZoom: [
              {
                show: res.data.data.length > 25,
                type: "slider",
                realtime: true,
                startValue: 0, // 重点
                // 重点-dataX.length表示x轴数据长度
                endValue: res.data.data.length - (res.data.data.length - 25),
                xAxisIndex: [0],
                bottom: "5%",
                left: "center",
              },
            ],
            grid: {
              bottom: res.data.data.length > 25 ? "20%" : "10%",
            },
            color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
            xAxis: [
              {
                type: "category",
                data: res.data.data.map((item) => item.segment),
                axisTick: { show: false },
                splitLine: { show: false },
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: "rgba(0, 0, 0, 0.45)",
                  },
                  rotate: 25,
                },
              },
            ],
            yAxis: [
              {
                type: "value",
                axisTick: { show: false },
                splitLine: { show: true },
              },
            ],
            series: [
              {
                barMaxWidth: 40,
                name: "人数",
                type: "bar",
                label: {
                  normal: {
                    show: true,
                    position: "top",
                    textStyle: {
                      color: "inherit",
                      fontSize: 9,
                    },
                  },
                },
                data: res.data.data.map((item) => {
                  if (item.isClassAverage) {
                    return {
                      value: item.total,
                      itemStyle: { color: "#FD8585" },
                    };
                  } else {
                    return item.total;
                  }
                }),
              },
            ],
          };
        } catch {
          this.loading = false;
        }
      } else {
        this.loading = false;
        this.chartOptions = null;
      }
    },
    async getrkjsreportscoredistributionRankData() {
      if (this.search.high) {
        let res;
        if (this.examType == 1) {
          res = await UniongetrkjsreportscoredistributionRank({
            ...this.search,
            ...this.params,
            viewSchoolId: this.$route.query.schoolId,
            id: this.statId,
          });
        } else {
          res = await getrkjsreportscoredistributionRank({
            ...this.search,
            ...this.params,
            id: this.statId,
          });
        }

        this.loading = false;
        this.chartOptions = {
          legend: {
            itemGap: 30,
          },
          dataZoom: [
            {
              show: res.data.data.length > 25,
              type: "slider",
              realtime: true,
              startValue: 0, // 重点
              // 重点-dataX.length表示x轴数据长度
              endValue: res.data.data.length - (res.data.data.length - 25),
              xAxisIndex: [0],
              bottom: "5%",
              left: "center",
            },
          ],
          color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
          grid: {
            bottom: res.data.data.length > 25 ? "20%" : "10%",
          },
          tooltip: {
            trigger: "axis",
            backgroundColor: "rgba(0,0,0,0.7)",
            borderColor: "rgba(0,0,0,0.7)",
            textStyle: {
              color: "#fff",
            },
            axisPointer: {
              type: "shadow",
              shadowStyle: {
                color: "rgba(0, 0, 0, 0.1)",
              },
            },

            valueFormatter: function (val) {
              return Math.abs(val);
            },
          },
          xAxis: [
            {
              type: "category",
              data: res.data.data.map((item) => item.name),
              axisTick: { show: false },
              splitLine: { show: false },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "rgba(0, 0, 0, 0.45)",
                },
                rotate: 25,
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              axisTick: { show: false },
              splitLine: { show: true },
            },
          ],
          series: [
            {
              name: "人数",
              type: "bar",
              barMaxWidth: 40,
              label: {
                normal: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "inherit",
                    fontSize: 9,
                  },
                },
              },
              data: res.data.data.map((item) => item.total),
            },
          ],
        };
      } else {
        this.$alert("请输入排名区间", "", {
          confirmButtonText: "确定",
          callback: () => {},
        });
        this.loading = false;
        this.chartOptions = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.branch {
  .operate {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 24px;
    .filter {
      display: flex;
      align-items: center;
      color: #5e6166;
      font-size: 14px;
      flex-wrap: wrap;
      .label {
        color: #080a09;
        font-size: 14px;
        font-weight: 550;
        margin-right: 24px;
      }
      .filter-item {
        display: flex;
        align-items: center;
        margin-right: 24px;
        font-size: 14px;
        color: #5e6166;
        .el-input {
          margin: 0 8px;
        }
        @media screen and (max-width: 1400px) {
          margin-bottom: 24px;
        }
      }
    }
  }
  .tag {
    height: 60px;
    background-color: #f2f8fe;
    line-height: 60px;
    box-sizing: border-box;
    padding: 0 12px;
    margin: 24px 0;
    color: #737677;
    font-size: 14px;
  }
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background: #2474ed;
  border-color: #2474ed;
  &:hover {
    color: #fff;
  }
}
::v-deep .el-radio-button__inner {
  &:hover {
    color: #2474ed;
  }
}
</style>
